export default [
  {
    path: 'notification',
    name: 'AdminNotifications',
    component: { render: (h) => h('router-view') },
    redirect: { name: 'AdminNotificationsEmail' },
    children: [
      {
        path: 'email',
        name: 'AdminNotificationsEmail',
        component: () => import('@/views/admin/notifications/Email.vue')
      },
      {
        path: 'slack',
        name: 'AdminNotificationsSlack',
        component: () => import('@/views/admin/notifications/Slack.vue')
      },
      {
        path: 'microsoft-teams',
        name: 'AdminNotificationsMicrosoftTeams',
        component: () => import('@/views/admin/notifications/MicrosoftTeams.vue')
      }
    ],
    meta: {
      headerText: 'Notification Admin',
      headerBackRoute: 'AdminHome',
      subNav: 'AdminNotifications'
    }
  }
]
