import Vue from 'vue'

function update ({ id, companyId, sourceId, sourceName } = {}) {
  const path = `/admin/external-integration-installations/${id}`

  const params = { companyId }
  const data = { sourceName, sourceId }
  return Vue.api.patch(path, { params, data })
}

export default {
  update
}
