import emailTemplateMappings from './emailTemplateMappings'
import externalIntegrationTeams from './externalIntegrationTeams'
import externalIntegrationTeamMapping from './externalIntegrationTeamMapping'
import externalIntegrationInstallations from './externalIntegrationInstallations'
import externalIntegrationUserMappings from './externalIntegrationUserMappings'
import externalIntegrationUsers from './externalIntegrationUsers'
import externalSystems from './externalSystems'
import recommendations from './recommendations'
import tasks from './tasks'
import userNotificationTemplates from './userNotificationTemplates'
import userNotificationTypes from './userNotificationTypes'
import users from './users'

export default {
  emailTemplateMappings,
  externalIntegrationTeams,
  externalIntegrationTeamMapping,
  externalIntegrationInstallations,
  externalIntegrationUserMappings,
  externalIntegrationUsers,
  externalSystems,
  recommendations,
  tasks,
  userNotificationTemplates,
  userNotificationTypes,
  users
}
