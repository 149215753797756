import Vue from 'vue'

function update ({
  id,
  companyId,
  externalIntegrationTeamSourceId
} = {}) {
  const path = `/admin/external-integration-installations/${id}/external-integration-team-mapping`

  const params = { companyId }
  const data = { externalIntegrationTeamSourceId }
  return Vue.api.patch(path, { params, data })
}

export default {
  update
}
