export default [
  {
    path: 'notification-configurations',
    name: 'AdminNotificationConfiguration',
    component: { render: (h) => h('router-view') },
    redirect: { name: 'AdminNotificationConfigurationUserNotificationType' },
    children: [
      {
        path: 'email-template-mapping',
        name: 'AdminNotificationConfigurationEmail',
        component: () => import('@/views/admin/notification-configurations/EmailTemplateMapping.vue')
      },
      {
        path: 'user-notification-types',
        name: 'AdminNotificationConfigurationUserNotificationType',
        component: () => import('@/views/admin/notification-configurations/UserNotificationTypes.vue')
      },
      {
        path: 'user-notification-templates',
        name: 'AdminNotificationConfigurationUserNotificationTemplate',
        component: () => import('@/views/admin/notification-configurations/UserNotificationTemplates.vue')
      }
    ],
    meta: {
      headerText: 'Notification Configuration',
      headerBackRoute: 'AdminHome',
      subNav: 'AdminNotificationConfiguration'
    }
  }
]
